<template>
  <div v-if="$hasPermissions(clientSession, ['CLIENT_REPORTS'], 1)">
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-show="isLoading">
        <Loading />
      </div>
      <div style="padding: 4px;height: 100%; width: 100%!important;">
        <!-- Filters Section -->
        <div class="filters-section">
          <div class="columns is-multiline is-desktop is-fullwidth">
            <div class="column is-full-mobile is-narrow">
              <b-field label="Month End Date">
                <b-datepicker v-model="formattedDate"
                placeholder="Select a date" editable
                :date-parser="customDateParser"
                style="max-width: 132px;"
                :date-formatter="customDateFormatter">
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-full-mobile is-one-fifth-desktop">
              <b-field label="Carrier">
                <multiselect
                  v-model="filters.selectedCarriersArray"
                  :options="carriers"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="All Carriers"
                  :loading="carriersLoading"
                  @select="fetchClients"
                  @remove="fetchClients"
                  label="label"
                  track-by="value">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-show="values.length > 0 && !isOpen">{{ values.length }} carriers selected </span>
                  </template>
                  <template slot="clear">
                    <div class="multiselect__clear" v-if="filters.selectedCarriersArray.length" @mousedown.prevent.stop="() => (filters.selectedCarriersArray = [])">
                      <i class="fas fa-times"></i>
                    </div>
                  </template>
                </multiselect>
              </b-field>
            </div>
            <div class="column is-full-mobile">
              <b-field label="Client">
                <multiselect
                  v-model="filters.selectedClientsArray"
                  :options="clients"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :loading="clientsLoading"
                  :preserve-search="true"
                  placeholder="All Clients"
                  label="label"
                  track-by="value">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"  v-show="values.length > 0 && !isOpen">{{ values.length }} clients selected</span>
                  </template>
                  <template slot="clear">
                    <div class="multiselect__clear" v-if="filters.selectedClientsArray.length" @mousedown.prevent.stop="() => (filters.selectedClientsArray = [])">
                      <i class="fas fa-times"></i>
                    </div>
                  </template>
                </multiselect>
              </b-field>
            </div>
            <div class="column is-full-mobile is-narrow">
              <b-field label="Policy Values">
                <b-select v-model="filters.hasPolicyValues" placeholder="Select Policy Values">
                  <option v-for="value in policyValues" :key="value.value" :value="value.value">
                    {{ value.text }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-full-mobile is-narrow is-flex is-align-items-bottom">
              <div class="control is-flex" style="align-items: end; padding-bottom: 6px;">
                <button class="button is-accent" @click="applyFilters">View Report</button>
              </div>
            </div>
          </div>
        </div>
        <div class="k-grid">
          <table>
            <tbody>
              <tr style="background-color: #9FA8DA; color: white;">
                <!--<td style="width: 81px; text-align: center; color: white !important;">Totals</td>-->
                <td>{{ clientExceptions.summary.totalUniqueClients }} Clients</td>
                <td style="width: 72px; font-size: 0.8rem;">{{ clientExceptions.summary.totalUniqueCarriers }} Carriers</td>
                <td style="width: 157px; text-align: center;">{{ clientExceptions.summary.totalUniqueParticipants }} Participants</td>
                <td style="width: 244px; text-align: center;">{{ clientExceptions.summary.totalUniquePolicies }} Policies</td>
                <td style="width: 157px; text-align: right;">{{ formatCurrency(clientExceptions.summary.totalEndingCashValue || 0) }}</td>
                <td style="width: 168px; text-align: right; padding-right: 22px;">{{ formatCurrency(clientExceptions.summary.totalEndingDeathBenefit || 0) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Grid
          v-if="!isLoading"
          :data-items="clientExceptions.value"
          :sortable="true"
          :pageable="{
            pageSizes: [10, 20, 50, 100]
          }"
          :columns="columns"
          :page-size="pagination.pageSize"
          :skip="pagination.skip"
          :total="clientExceptions.count"
          @pagechange="onPageChange"
          @sortchange="sortChangeHandler"
          :sort="sort"
          :style="{ maxHeight: 'none', height: 'auto' }"
        >
          <template v-slot:CurrencyCell="{ props }">
            <td style="text-align: right;">
              {{ formatCurrency(props.dataItem[props.field]) }}
            </td>
          </template>
          <template v-slot:DateCell="{ props }">
            <td style="text-align: center;">
              {{ getFormattedDate(props.dataItem[props.field]) }}
            </td>
          </template>
          <template v-slot:ParticipantsCell="{ props }">
            <td style="text-align: left;">
              {{ props.dataItem['lastName'] + ", " + props.dataItem['firstName'] }}
            </td>
          </template>
        </Grid>
      </div>
    </div>
  </div>
  <div v-else>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">No Permission</h1>
    </div>
    <div class="has-text-centered ">
      <b>You Lack The Permissions Required To View This Page</b>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import { Grid } from '@progress/kendo-vue-grid'
import Loading from '../Loading'
import moment from 'moment'

const pageName = 'Client Exception Report'

export default {
  components: {
    Loading,
    Grid
  },
  data () {
    return {
      pageheading: pageName,
      isLoading: true,
      clientsLoading: true,
      carriersLoading: true,
      clientExceptions: {
        summary: {},
        count: 0,
        value: []
      },
      carriers: [],
      clients: [],
      sort: [{ field: 'clientID', dir: 'asc' }],
      filters: {
        selectedClientsArray: [],
        selectedCarriersArray: [],
        hasPolicyValues: 0,
        monthEndDate: null
      },
      policyValues: [
        { text: 'All Values', value: 0 },
        { text: 'No Exception', value: 1 },
        // { text: 'Partial Exception', value: 2 },
        { text: 'Exception', value: 3 }
      ],
      columns: [
        {
          field: 'clientId',
          title: 'Client ID',
          width: '94px',
          sortable: true
        },
        {
          field: 'clientName',
          title: 'Client Name',
          sortable: true
        },
        {
          field: 'carrierCode',
          title: 'Carrier',
          width: '86px',
          sortable: true
        },
        {
          field: 'lastName',
          title: 'Participant',
          width: '170px',
          sortable: true,
          cell: 'ParticipantsCell'
        },
        {
          field: 'policyNumber',
          title: 'Policy Number',
          width: '138px',
          sortable: true
        },
        {
          field: 'policyDate',
          title: 'Policy Date',
          width: '120px',
          sortable: true,
          cell: 'DateCell'
        },
        {
          field: 'endingCashValue',
          title: 'Ending Cash Value',
          numeric: true,
          sortable: true,
          width: '170px',
          cell: 'CurrencyCell'
        },
        {
          field: 'endingDeathBenefit',
          title: 'Ending Death Benefit',
          width: '182px',
          numeric: true,
          sortable: true,
          cell: 'CurrencyCell'
        }
      ],
      pagination: {
        take: 10,
        skip: 0,
        total: 0,
        pageSize: 10
      },
      dateString: null
    }
  },
  methods: {
    applyFilters () {
      this.fetchClientExceptions()
    },
    getFormattedDate (date) {
      return new Date(date).toLocaleDateString('en-US')
    },
    formatCurrency (value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value)
    },
    async fetchClientExceptions () {
      this.isLoading = true
      try {
        const result = await this.api().getExceptionClientReport({
          monthEndDateStr: this.dateString,
          carrierFilter: this.selectedCarriers,
          clientFilter: this.selectedClients,
          stateFilter: this.filters.hasPolicyValues,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortField: this.sortObject.field,
          sortDirection: this.sortObject.dir
        })

        if (result && result.value) {
          result.value = result.value.map(i => {
            i.policyDate = new Date(i.policyDate)
            return i
          })
        }
        this.clientExceptions = result
        this.clientExceptions.summary = this.clientExceptions.summary || {}
        if (this.clientExceptions.summary != null) this.dateString = this.clientExceptions.summary.monthEndDate
      } catch (error) {
        console.error('Error fetching client exceptions:', error)
        this.errorToast('Failed to fetch client exceptions.')
      }
      this.isLoading = false
    },
    async fetchClients () {
      this.clientsLoading = true
      try {
        const result = await this.api().getClientsToPolicies()
        this.clients = result.map(client => ({
          value: client.id,
          label: client.name
        }))
      } catch (error) {
        console.error('Error fetching clients:', error)
        this.errorToast('Failed to fetch clients.')
      }
      this.clientsLoading = false
    },
    async fetchCarriers () {
      this.carriersLoading = true
      try {
        const results = await this.api().getCarriers()
        this.carriers = results.map(carrier => ({
          value: carrier.code,
          label: carrier.code
        }))
      } catch (error) {
        console.error('Error fetching carriers:', error)
        this.errorToast('Failed to fetch carriers.')
      }
      this.carriersLoading = false
    },
    onPageChange ({ page }) {
      if (this.pagination.pageSize !== page.take) {
        this.pagination.pageSize = page.take
      }
      this.pagination.skip = page.skip
      this.pagination.pageSize = page.take
      this.pagination.page = (page.skip / page.take) + 1
      this.fetchClientExceptions()
    },
    sortChangeHandler ({ sort }) {
      this.sort = sort
      this.fetchClientExceptions()
    },
    customDateParser (dateString) {
      const date = moment.utc(dateString, 'YYYY-MM-DD', true)
      return date.isValid() ? date.toDate() : null
    },
    customDateFormatter (date) {
      return date ? moment.utc(date).format('YYYY-MM-DD') : ''
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    selectedClients () {
      return this.filters.selectedClientsArray.length > 0
        ? this.filters.selectedClientsArray.map(client => client.value).join(',') : ''
    },
    selectedCarriers () {
      return this.filters.selectedCarriersArray.length > 0
        ? this.filters.selectedCarriersArray.map(carrier => carrier.value).join(',') : ''
    },
    sortObject () {
      let sort = { field: 'clientID', dir: 'asc' }
      if (this.sort.length === 1) {
        sort = this.sort[0]
      }
      return sort
    },
    formattedDate: {
      get () {
        return this.dateString ? new Date(this.dateString) : null
      },
      set (value) {
        if (value) {
          const year = value.getFullYear()
          const month = String(value.getMonth() + 1).padStart(2, '0') // month is zero-indexed
          const day = String(value.getDate()).padStart(2, '0')
          this.dateString = `${year}-${month}-${day}`
        } else {
          this.dateString = null
        }
      }
    }
  },
  mounted () {
    this.pagination.pageSize = 15
    this.fetchCarriers()
    this.fetchClients()
    this.fetchClientExceptions()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tags{
  min-height: 36px;
  height: 36px;
  padding: 6px 40px 0 6px;
}
.multiselect__clear {
    position: absolute;
    right: 14px;
    height: 35px;
    width: 40px;
    display: flex;
    cursor: pointer;
    z-index: 3;
    align-items: center;
    color: #888;
}
.k-grid td:first-child {
  color: #424242 !important;
}

.k-grid th:first-child {
  color: #424242 !important;
}
.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #1f314d;
    right: 1.125em;
    z-index: 30;
}
.filters-section .select select, .select:not(.is-multiple), .button, .input{
  height: 2.2em;
}
</style>

<style scoped>
.filters-section {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem
}
</style>
